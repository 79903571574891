import { useState, useEffect, Fragment } from 'react'
import List from '@mui/material/List'
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  Button,
  IconButton,
  Box,
} from '@mui/material'
import {
  Add,
  BarChart,
  AdminPanelSettings,
  InfoOutlined,
  ExitToAppOutlined,
  PushPin,
  AssignmentInd,
  Link,
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { getDecodedToken } from '@resolvex/resolvex-sdk'
import { ContactModal } from 'components'
import { getContext } from 'utils/store'
import { Drawer } from './drawer'
import logo from '../logo.png'
import { NavBarListItem } from './navBarListItem'
import {
  URL_ADMIN_BASIC,
  URL_ADMIN_RESOLVEX,
  URL_ADMIN_TIMESHEET,
  URL_ANALYTICS_GENERAL,
  URL_ANALYTICS_REASSURANCE,
  URL_ANALYTICS_RESOURCE,
  URL_ANALYTICS_TABLEAU,
  URL_CASEFORM_DOWNLOADS,
  URL_CASEFORM_SESSIONS,
  URL_CASEFORM_PATIENTS,
  URL_CASEFORM_PATIENTS_ADD,
  URL_CASEFORM_RESOURCES,
  URL_CASEFORM_RESOURCES_ADD,
} from 'utils/variables/constants'
import { QuickLinksModal } from './quickLinks/quickLinksModal'

export const NavBar = (): JSX.Element => {
  const theme = useTheme()
  const { handleLogout, navigate, hasFeatureAccess } = getContext()
  const { firstName, lastName } = getDecodedToken()

  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [quickLinkModalOpen, setQuickLinkModalOpen] = useState(false)

  const [subMenuCollapsed, setSubMenuCollapsed] = useState<
    Record<string, boolean>
  >({})

  const [drawerPinned, setDrawerPinned] = useState(
    localStorage.getItem('user-pref-drawerPinned') === 'true'
  )
  const [drawerOpen, setDrawerOpen] = useState(
    localStorage.getItem('user-pref-drawerPinned') === 'true'
  )

  const contrastTextPrimary = theme.palette.primary.light

  const contactModalState = {
    openState: contactModalOpen,
    handleClose: () => setContactModalOpen(false),
  }

  // Always close list item sub menu on button click
  useEffect(() => {
    if (!drawerPinned) setSubMenuCollapsed({})
    // eslint-disable-next-line
  }, [drawerOpen])

  const handleDrawerOpen = () => {
    if (!drawerPinned) setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    if (!drawerPinned) setDrawerOpen(false)
  }

  const handleSubMenuOpen = (linkText: string): void => {
    setSubMenuCollapsed((currentValue) => ({
      ...currentValue,
      [linkText]: !currentValue[linkText],
    }))
  }

  const handleRouteChange = (link: string) => {
    const linkLowerCase = link.toLowerCase()
    setDrawerOpen(false)
    navigate(linkLowerCase)
  }

  const subMenuCaseform = [
    {
      label: 'Contacts',
      url: URL_CASEFORM_PATIENTS,
      isEnabled: hasFeatureAccess(URL_CASEFORM_PATIENTS),
    },
    {
      label: 'Sessions',
      url: URL_CASEFORM_SESSIONS,
      isEnabled: hasFeatureAccess(URL_CASEFORM_SESSIONS),
    },
    {
      label: 'Resources',
      url: URL_CASEFORM_RESOURCES,
      isEnabled: hasFeatureAccess(URL_CASEFORM_RESOURCES),
    },
    {
      label: 'Downloads',
      url: URL_CASEFORM_DOWNLOADS,
      isEnabled: hasFeatureAccess(URL_CASEFORM_DOWNLOADS),
    },
  ]

  const subMenuAnalytics = [
    {
      label: 'General',
      url: URL_ANALYTICS_GENERAL,
      isEnabled: hasFeatureAccess(URL_ANALYTICS_GENERAL),
    },
    {
      label: 'Resource',
      url: '/analytics/resource',
      isEnabled: hasFeatureAccess(URL_ANALYTICS_RESOURCE),
    },
    {
      label: 'Reassurance',
      url: URL_ANALYTICS_REASSURANCE,
      isEnabled: hasFeatureAccess(URL_ANALYTICS_REASSURANCE),
    },
    {
      label: 'Tableau',
      url: URL_ANALYTICS_TABLEAU,
      isEnabled: hasFeatureAccess(URL_ANALYTICS_TABLEAU),
    },
  ]

  const subMenuAdmin = () => {
    if (
      hasFeatureAccess(URL_ADMIN_BASIC) &&
      hasFeatureAccess(URL_ADMIN_RESOLVEX)
    ) {
      return [
        {
          label: 'Timesheet',
          url: URL_ADMIN_TIMESHEET,
          isEnabled: hasFeatureAccess(URL_ADMIN_TIMESHEET),
          isHidden: hasFeatureAccess(URL_ADMIN_TIMESHEET),
        },
        {
          label: 'Basic',
          url: URL_ADMIN_BASIC,
          isEnabled: hasFeatureAccess(URL_ADMIN_BASIC),
          isHidden: hasFeatureAccess(URL_ADMIN_BASIC),
        },
        {
          label: 'Resolvex',
          url: URL_ADMIN_RESOLVEX,
          isEnabled: hasFeatureAccess(URL_ADMIN_RESOLVEX),
          isHidden: hasFeatureAccess(URL_ADMIN_RESOLVEX),
        },
      ]
    }

    return [
      {
        label: 'Timesheet',
        url: URL_ADMIN_TIMESHEET,
        isEnabled: hasFeatureAccess(URL_ADMIN_TIMESHEET),
        isHidden: hasFeatureAccess(URL_ADMIN_TIMESHEET),
      },
      {
        label: 'Basic',
        url: URL_ADMIN_BASIC,
        isEnabled: hasFeatureAccess(URL_ADMIN_BASIC),
        isHidden: hasFeatureAccess(URL_ADMIN_BASIC),
      },
    ]
  }

  const listItems = [
    {
      name: 'Caseform',
      icon: <AssignmentInd sx={{ color: 'primary.light' }} />,
      isEnabled: hasFeatureAccess(URL_CASEFORM_PATIENTS),
      subMenu: subMenuCaseform,
      customItems: [
        {
          label: 'Add Contact',
          disabled: !hasFeatureAccess(URL_CASEFORM_PATIENTS),
          onClick: () => handleRouteChange(URL_CASEFORM_PATIENTS_ADD),
        },
        {
          label: 'Add Resource',
          disabled: !hasFeatureAccess(URL_CASEFORM_RESOURCES),
          onClick: () => handleRouteChange(URL_CASEFORM_RESOURCES_ADD),
        },
      ].map((item, index) => (
        <Button
          key={index + item.label}
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          disableElevation
          sx={{ boxShadow: 'none' }}
          endIcon={<Add color="info" />}
          disabled={item.disabled}
          onClick={() => item.onClick()}
        >
          {item.label}
        </Button>
      )),
    },
    {
      name: 'Analytics',
      icon: <BarChart sx={{ color: 'primary.light' }} />,
      isEnabled: hasFeatureAccess(URL_ANALYTICS_GENERAL),
      subMenu: subMenuAnalytics,
    },
    {
      name: 'Admin',
      icon: <AdminPanelSettings sx={{ color: 'primary.light' }} />,
      isEnabled:
        hasFeatureAccess(URL_ADMIN_BASIC) ||
        hasFeatureAccess(URL_ADMIN_RESOLVEX) ||
        hasFeatureAccess(URL_ADMIN_TIMESHEET),
      subMenu: subMenuAdmin(),
    },
  ]

  return (
    <>
      <Drawer
        variant="permanent"
        open={drawerPinned ? drawerPinned : drawerOpen}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        sx={{
          '& .MuiListItem-root': {
            color: contrastTextPrimary,
            borderBottom: 0,
          },
          '& .MuiTypography-root': { color: contrastTextPrimary },
          '& .MuiSvgIcon-root': {
            transition: 'all .2s linear',
          },
          '& .MuiDivider-root': { borderColor: theme.palette.primary.main },
        }}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <Avatar
                src={logo}
                sx={{
                  backgroundColor: 'transparent',
                  width: 36,
                  height: 36,
                  transition: 'all',
                }}
              />
            </ListItemIcon>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              onClick={() =>
                setDrawerPinned((currentValue) => {
                  localStorage.setItem(
                    'user-pref-drawerPinned',
                    `${!currentValue}`
                  )
                  return !currentValue
                })
              }
            >
              <PushPin
                sx={{
                  fontSize: 16,
                  transform: drawerPinned ? '' : 'rotate(180deg)',
                  color: 'primary.light',
                }}
              />
            </IconButton>
          </ListItem>
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              minHeight: '60px',
            }}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  backgroundColor: 'transparent',
                  width: 28,
                  height: 28,
                }}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="body2"
                color="primary.light"
                sx={{
                  display: 'inline-block',
                  whiteSpace: drawerOpen ? 'pre-line' : 'nowrap',
                }}
              >{`${firstName} ${lastName}`}</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <Divider
          variant="middle"
          style={{
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
          }}
        />
        <List>
          {listItems.map((listItem, index) => (
            <Fragment key={index}>
              <NavBarListItem
                open={subMenuCollapsed[listItem.name]}
                key={index}
                index={index}
                linkText={listItem.name}
                linkTextIcon={listItem.icon}
                isEnabled={listItem.isEnabled}
                subList={listItem.subMenu}
                customMenu={listItem.customItems}
                handleRouteChange={handleRouteChange}
                handleSubMenuOpen={handleSubMenuOpen}
              />
            </Fragment>
          ))}
        </List>
        <Divider
          variant="middle"
          style={{
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
          }}
        />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setQuickLinkModalOpen(true)}>
              <ListItemIcon>
                <Link sx={{ color: 'primary.light' }} />
              </ListItemIcon>
              <ListItemText primary="Quick links" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => setContactModalOpen(true)}>
              <ListItemIcon>
                <InfoOutlined sx={{ color: 'primary.light' }} />
              </ListItemIcon>
              <ListItemText primary="Contact us" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <ExitToAppOutlined sx={{ color: 'primary.light' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <ContactModal state={contactModalState} />
      {quickLinkModalOpen && (
        <QuickLinksModal
          open={quickLinkModalOpen}
          onClose={() => setQuickLinkModalOpen(false)}
        />
      )}
    </>
  )
}
